<template>
  <h1>LinkedIn Raw Data</h1>
</template>

<script>
export default {
    name:"LinkedInRawData"
}
</script>

<style>

</style>